import { useQuery } from '@tanstack/react-query';
import { generateIssueManagerSearchKey } from 'src/api/lib/issue-manager';
import { issueManagerMeilisearchSearchKeyQueryKeys } from 'src/api/query-keys/issue-manager/meilisearch-search-key';
import { useUserStore } from 'src/store/user-store';

export const useGenerateIssueManagerSearchKey = (enabled = true) => {
  const { directoryToken } = useUserStore();

  return useQuery({
    queryFn: generateIssueManagerSearchKey,
    queryKey: issueManagerMeilisearchSearchKeyQueryKeys.detail(
      directoryToken ?? '',
    ),
    enabled,
  });
};
