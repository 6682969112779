import { useEffect } from 'react';
import { useUserStore } from 'src/store/user-store';
import { useAcceptIssueManagerInvitation } from './api/use-accept-issue-manager-invitation';
import { useAvailableIssueManagerContexts } from './api/use-available-issue-manager-contexts';

export const useAcceptCurrentIssueManagerInvitation = (enabled: boolean) => {
  const directoryToken = useUserStore().directoryToken;

  const { data, isLoading } = useAvailableIssueManagerContexts(enabled);

  const selectedIssueManagerContext = data?.find(
    context => context.token === directoryToken,
  );

  const { mutate } = useAcceptIssueManagerInvitation();

  useEffect(() => {
    if (
      selectedIssueManagerContext &&
      !isLoading &&
      directoryToken &&
      selectedIssueManagerContext.invitationStatus === 'PENDING'
    ) {
      mutate(selectedIssueManagerContext.issueManagerToken);
    }
  }, [selectedIssueManagerContext, isLoading, directoryToken, mutate]);
};
