import CloseIcon from '@mui/icons-material/Close';
import { Box, Card, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import { useUserStore } from 'src/store/user-store';
import applications from '../../constants/applications';
import MarketplaceApplication from '../molecules/marketplace-application';

interface Props {
  open: boolean;
  onCancel: () => void;
}

type SupportedViewContext = 'site' | 'tenant';

export default function MarketplaceDialog({ open, onCancel }: Props) {
  const { t } = useTranslation();
  const { viewContext } = useUserStore();
  const theme = useTheme();
  const { mode } = theme.palette;

  const isDevEnvironment = import.meta.env.DEV;
  const shortEnvName = isDevEnvironment ? 'dev' : 'prod';

  return (
    <Dialog open={open} onClose={onCancel}>
      <Box
        sx={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          padding: '20px',
        }}>
        <Card
          sx={{
            maxWidth: 686,
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            gap: 0.5,
            py: 3,
            px: 4,
          }}
          onClick={event => event.stopPropagation()}>
          {applications.map(application => (
            <MarketplaceApplication
              key={application.name}
              logo={application.logo[mode]}
              name={application.name}
              description={t(application.description)}
              disabled={application.isDisabled}
              isAvailable={
                !!application.href.prod.site || !!application.href.prod.tenant
              }
              href={
                application.href[shortEnvName][
                  (viewContext as SupportedViewContext) ?? 'site'
                ] ?? '/'
              }
            />
          ))}
          <CloseIcon
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              cursor: 'pointer',
              color: '#FFF',
              zIndex: 1000,
            }}
            onClick={onCancel}
          />
        </Card>
      </Box>
    </Dialog>
  );
}
