import { useQuery } from '@tanstack/react-query';
import { useUserStore } from 'src/store/user-store';
import { ApiError } from 'src/types/interfaces/api-error';
import { fetchApplications } from '../api/lib/applications';
import { siteApplicationsKeys } from '../api/query-keys/applications';
import { Application } from '../types/application';

export const useApplications = (enabled: boolean = true) => {
  const { directoryToken } = useUserStore();

  return useQuery<Application[], ApiError>({
    queryFn: fetchApplications,
    queryKey: siteApplicationsKeys.list(directoryToken ?? ''),
    enabled,
  });
};
