import { useMutation, useQueryClient } from '@tanstack/react-query';
import { acceptIssueManagerInvitation } from 'src/api/lib/issue-manager';
import { availableIssueManagerContextsQueryKeys } from 'src/api/query-keys/issue-manager/available-issue-manager-contexts';

export const useAcceptIssueManagerInvitation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (issueManagerToken: string) =>
      acceptIssueManagerInvitation(issueManagerToken),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: availableIssueManagerContextsQueryKeys.all,
      });
    },
  });
};
