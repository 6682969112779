import { lazy } from 'react';
import type { DataRouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';
import { AuthGuard } from 'src/components/guards/auth-guard';
import { Loadable } from 'src/components/loadable';
import { Layout } from 'src/layout/main-layout';

const EmployeesContainer = Loadable(
  lazy(() =>
    import('../../features/tenant-employees/containers/employees').then(
      module => ({
        default: module.EmployeesContainer,
      }),
    ),
  ),
);

const Employees = Loadable(
  lazy(() =>
    import(
      '../../features/tenant-employees/containers/employees/employees'
    ).then(module => ({
      default: module.EmployeesContainer,
    })),
  ),
);

const Polls = Loadable(
  lazy(() =>
    import('../../features/tenant-polls/containers/polls').then(module => ({
      default: module.PollsContainer,
    })),
  ),
);

const PollDetails = Loadable(
  lazy(() =>
    import('../../features/tenant-polls/containers/[poll-id]').then(module => ({
      default: module.PollDetailsContainer,
    })),
  ),
);

const CreatePoll = Loadable(
  lazy(() =>
    import('../../features/tenant-polls/containers/create-poll').then(
      module => ({
        default: module.CreatePollContainer,
      }),
    ),
  ),
);

const EditPoll = Loadable(
  lazy(() =>
    import('../../features/tenant-polls/containers/edit-poll').then(module => ({
      default: module.EditPollContainer,
    })),
  ),
);

const ApplicationsContainer = Loadable(
  lazy(() =>
    import('../../features/tenant/containers/applications').then(module => ({
      default: module.ApplicationsContainer,
    })),
  ),
);

const ApplicationContainer = Loadable(
  lazy(() =>
    import('../../features/tenant/containers/applications/[id]').then(
      module => ({
        default: module.ApplicationContainer,
      }),
    ),
  ),
);

const MobileAppManagementContainer = Loadable(
  lazy(() =>
    import('../../features/tenant/containers/mobile-app-management').then(
      module => ({
        default: module.MobileAppManagementContainer,
      }),
    ),
  ),
);

const OrganizationDetailsContainer = Loadable(
  lazy(() =>
    import('../../features/tenant/containers/organization-details').then(
      module => ({
        default: module.OrganizationDetailsContainer,
      }),
    ),
  ),
);

const EmployeesPermissions = Loadable(
  lazy(() =>
    import(
      '../../features/tenant-employees-permissions/containers/employees-permissions'
    ).then(module => ({
      default: module.EmployeesPermissionsContainer,
    })),
  ),
);

const OrganizationDetails = Loadable(
  lazy(() =>
    import(
      '../../features/tenant/containers/organization-details/organization-details'
    ).then(module => ({
      default: module.OrganizationDetailsContainer,
    })),
  ),
);

const AdminsContainer = Loadable(
  lazy(() =>
    import('../../features/tenant/containers/organization-details/admins').then(
      module => ({
        default: module.AdminsContainer,
      }),
    ),
  ),
);

const InvitationSettings = Loadable(
  lazy(() =>
    import('../../features/tenant/containers/invitation-settings').then(
      module => ({
        default: module.InvitationSettingsContainer,
      }),
    ),
  ),
);

const EmployeeJoinMethod = Loadable(
  lazy(() =>
    import(
      '../../features/tenant-employee-join-method/containers/employee-join-method'
    ).then(module => ({
      default: module.EmployeeJoinMethodContainer,
    })),
  ),
);

const Requests = Loadable(
  lazy(() =>
    import('../../features/tenant/containers/employees/requests').then(
      module => ({
        default: module.RequestsContainer,
      }),
    ),
  ),
);

const Domains = Loadable(
  lazy(() =>
    import('../../features/tenant-domains/containers/domains').then(module => ({
      default: module.DomainsContainer,
    })),
  ),
);

const Employee = Loadable(
  lazy(() =>
    import(
      '../../features/tenant-employees/containers/employee/[employeeToken]'
    ).then(module => ({
      default: module.EmployeeContainer,
    })),
  ),
);
const EmployeeApplications = Loadable(
  lazy(() =>
    import(
      '../../features/tenant-employees/containers/employee/applications'
    ).then(module => ({
      default: module.ApplicationsContainer,
    })),
  ),
);
const EmployeeActions = Loadable(
  lazy(() =>
    import('../../features/tenant-employees/containers/employee/actions').then(
      module => ({
        default: module.ActionsContainer,
      }),
    ),
  ),
);

const ContactForm = Loadable(
  lazy(() =>
    import('../../features/tenant-contact-form/containers/contact-form').then(
      module => ({
        default: module.ContactFormContainer,
      }),
    ),
  ),
);

const PostsContainer = Loadable(
  lazy(() =>
    import('../../features/tenant-posts/containers/posts/posts').then(
      module => ({
        default: module.PostsPage,
      }),
    ),
  ),
);

const PostDetailsContainer = Loadable(
  lazy(() =>
    import('../../features/tenant-posts/containers/posts/[postToken]').then(
      module => ({
        default: module.default,
      }),
    ),
  ),
);

const CreatePostContainer = Loadable(
  lazy(() =>
    import('../../features/tenant-posts/containers/posts/create').then(
      module => ({
        default: module.CreatePostContainer,
      }),
    ),
  ),
);

const EditPostContainer = Loadable(
  lazy(() =>
    import('../../features/tenant-posts/containers/posts/edit').then(
      module => ({
        default: module.EditPostContainer,
      }),
    ),
  ),
);

const EventsContainer = Loadable(
  lazy(() =>
    import('../../features/tenant-events/containers/events/events').then(
      module => ({
        default: module.EventsContainer,
      }),
    ),
  ),
);

const EventDetailsContainer = Loadable(
  lazy(() =>
    import('../../features/tenant-events/containers/events/[eventToken]').then(
      module => ({
        default: module.EventDetailsContainer,
      }),
    ),
  ),
);

const CreateEventContainer = Loadable(
  lazy(() =>
    import('../../features/tenant-events/containers/events/create').then(
      module => ({
        default: module.CreateEventContainer,
      }),
    ),
  ),
);

const EditEventContainer = Loadable(
  lazy(() =>
    import('../../features/tenant-events/containers/events/edit').then(
      module => ({
        default: module.EditEventContainer,
      }),
    ),
  ),
);

const TenantDetailsContainer = Loadable(
  lazy(() =>
    import('../../features/tenant-details/containers/tenant-details').then(
      module => ({
        default: module.TenantDetailsContainer,
      }),
    ),
  ),
);

const MessagesContainer = Loadable(
  lazy(() =>
    import('../../features/tenant-messages/containers/messages').then(
      module => ({
        default: module.default,
      }),
    ),
  ),
);

const MessageDetailsContainer = Loadable(
  lazy(() =>
    import('../../features/tenant-messages/containers/[messageToken]').then(
      module => ({
        default: module.default,
      }),
    ),
  ),
);

const CreateMessageContainer = Loadable(
  lazy(() =>
    import('../../features/tenant-messages/containers/create-message').then(
      module => ({
        default: module.default,
      }),
    ),
  ),
);

const EditMessageContainer = Loadable(
  lazy(() =>
    import('../../features/tenant-messages/containers/edit-message').then(
      module => ({
        default: module.default,
      }),
    ),
  ),
);

const AuditLogsContainer = Loadable(
  lazy(() =>
    import(
      '../../features/tenant-audit-logs/containers/tenant-audit-logs'
    ).then(module => ({
      default: module.TenantAuditLogsContainer,
    })),
  ),
);

const routes: DataRouteObject[] = [
  {
    path: '/',
    id: 'employees',
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        id: '/employees',
        path: '/',
        element: <Navigate to="/employees" replace />,
      },
    ],
  },
  {
    id: 'employees-container',
    path: 'employees',
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        id: 'employees',
        path: '/employees',
        element: <EmployeesContainer />,
        children: [
          {
            id: 'employees',
            path: '/employees/',
            element: <Employees />,
          },
          {
            id: 'employees',
            path: '/employees/requests',
            element: <Requests />,
          },
        ],
      },
      {
        id: 'employee-details',
        path: '/employees/:employeeToken',
        element: <Employee />,
        children: [
          {
            id: 'employee-applications',
            path: '/employees/:employeeToken/applications',
            element: <EmployeeApplications />,
          },
          {
            id: 'employee-actions',
            path: '/employees/:employeeToken/actions',
            element: <EmployeeActions />,
          },
        ],
      },
    ],
  },
  {
    id: 'publications-container',
    path: 'publications',
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        id: 'publications',
        index: true,
        element: <Navigate to="/publications/polls" replace />,
      },
      {
        id: 'publications-polls',
        path: '/publications/polls',
        element: <Polls />,
      },
      {
        id: 'publications-posts',
        path: '/publications/posts',
        element: <PostsContainer />,
      },
      {
        id: 'publications-events',
        path: '/publications/events',
        element: <EventsContainer />,
      },
      {
        id: 'publications-messages',
        path: '/publications/messages',
        element: <MessagesContainer />,
      },
      {
        id: 'publications-posts-details',
        path: '/publications/posts/:postToken',
        element: <PostDetailsContainer />,
      },
      {
        id: 'publications-posts-edit',
        path: '/publications/posts/:postToken/edit',
        element: <EditPostContainer />,
      },
      {
        id: 'publications-posts-create',
        path: '/publications/posts/create',
        element: <CreatePostContainer />,
      },
      {
        id: 'publications-create-poll',
        path: '/publications/polls/create',
        element: <CreatePoll />,
      },
      {
        id: 'publications-poll-details',
        path: '/publications/polls/:pollToken',
        element: <PollDetails />,
      },
      {
        id: 'publications-edit-poll',
        path: '/publications/polls/:pollToken/edit',
        element: <EditPoll />,
      },
      {
        id: 'publications-events-details',
        path: '/publications/events/:eventToken',
        element: <EventDetailsContainer />,
      },
      {
        id: 'publications-events-edit',
        path: '/publications/events/:eventToken/edit',
        element: <EditEventContainer />,
      },
      {
        id: 'publications-events-create',
        path: '/publications/events/create',
        element: <CreateEventContainer />,
      },
      {
        id: 'publications-messages-details',
        path: '/publications/messages/:messageToken',
        element: <MessageDetailsContainer />,
      },
      {
        id: 'publications-messages-create',
        path: '/publications/messages/create',
        element: <CreateMessageContainer />,
      },
      {
        id: 'publications-messages-edit',
        path: '/publications/messages/:messageToken/edit',
        element: <EditMessageContainer />,
      },
    ],
  },
  {
    id: 'mobile-app-management-container',
    path: 'mobile-app-management',
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        id: 'mobile-app-management',
        path: '/mobile-app-management',
        element: <MobileAppManagementContainer />,
        children: [
          {
            id: 'applications-container',
            index: true,
            element: <ApplicationsContainer />,
          },

          {
            id: 'employees-permissions',
            path: '/mobile-app-management/employees-permissions',
            element: <EmployeesPermissions />,
          },
        ],
      },
      {
        id: 'application-details',
        path: '/mobile-app-management/applications/:token',
        element: <ApplicationContainer />,
      },
    ],
  },
  {
    id: 'invitation-settings-container',
    path: 'invitation-settings',
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        id: 'invitation-settings',
        path: '/invitation-settings',
        element: <InvitationSettings />,
        children: [
          {
            id: 'employee-join-method',
            index: true,
            element: <EmployeeJoinMethod />,
          },
          {
            id: 'domains',
            path: '/invitation-settings/domains',
            element: <Domains />,
          },
        ],
      },
    ],
  },
  {
    id: 'organization-details-container',
    path: 'organization-details',
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        id: 'organization-details',
        path: '/organization-details',
        element: <OrganizationDetailsContainer />,
        children: [
          {
            index: true,
            id: 'organization-details',
            element: <OrganizationDetails />,
          },
          {
            id: 'organization-details-admins',
            path: '/organization-details/admins',
            element: <AdminsContainer />,
          },
        ],
      },
    ],
  },
  {
    id: 'site-details-container',
    path: 'site-details',
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        id: 'site-details',
        path: '/site-details',
        element: <TenantDetailsContainer />,
      },
    ],
  },
  {
    id: 'contact-form-container',
    path: 'contact-form',
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        id: 'contact-form',
        index: true,
        element: <ContactForm />,
      },
    ],
  },
  {
    id: 'tenant-audit-logs-container',
    path: 'audit-logs',
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        id: 'tenant-audit-logs',
        index: true,
        element: <AuditLogsContainer />,
      },
    ],
  },
];

export default routes;
