import { Box } from '@mui/material';
import * as Sentry from '@sentry/react';
import { Helmet } from 'react-helmet-async';
import { useRoutes } from 'react-router-dom';
import commonRoutes from './common-routes';
import Loader from './components/atoms/loader/loader';
import { baseHeaderAppName } from './config';
import { useAuth } from './containers/contexts/auth-provider';
import { ApplicationToken } from './features/applications/types/enums/application-token';
import { useApplications } from './features/site/hooks/use-applications';
import { useMeilisearchApiKey } from './hooks/api/use-meilisearch-api-key';
import { useAcceptCurrentIssueManagerInvitation } from './hooks/use-accept-current-issue-manager-invitation';
import { useConfirmJoinCurrentlySelectedSite } from './hooks/use-confirm-join-currently-selected-site';
import { useConfirmJoinCurrentlySelectedTenant } from './hooks/use-confirm-join-currently-selected-tenant';
import { useSetInitialOrganizations } from './hooks/use-set-initial-organizations';
import { AbilityProvider } from './permissions/can';
import { ViewContext, useUserStore } from './store/user-store';
import { UserRole } from './types/enum/user-role';
import { issueManagerRoutes } from './view-contexts/issue-manager/routes';
import siteRoutes from './view-contexts/site/routes';
import tenantRoutes from './view-contexts/tenant/routes';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/single-building-app-api.signalos\.app/,
    /^https:\/\/single-building-app-meili.signalos\.app/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const getRoutesForViewContext = (currentViewContext: ViewContext) => {
  switch (currentViewContext) {
    case 'site':
      return [...siteRoutes, ...commonRoutes];

    case 'tenant':
      return [...tenantRoutes, ...commonRoutes];

    case 'issue-manager':
      return [...issueManagerRoutes, ...commonRoutes];
  }
};

function App() {
  const auth = useAuth();
  const user = useUserStore();
  const isLoggedIn = !!auth.session?.access_token;
  const content = useRoutes(
    getRoutesForViewContext(user.viewContext ?? 'tenant'),
  );

  const {
    isLoadingAvailableTenants,
    isLoadingAvailableSites,
    isLoadingAvailableIssueManagerContexts,
  } = useSetInitialOrganizations(isLoggedIn);

  useConfirmJoinCurrentlySelectedTenant(
    user.viewContext === 'tenant' && !isLoadingAvailableTenants && isLoggedIn,
  );

  useConfirmJoinCurrentlySelectedSite(
    user.viewContext === 'site' && !isLoadingAvailableSites && isLoggedIn,
  );

  useAcceptCurrentIssueManagerInvitation(
    user.viewContext === 'issue-manager' &&
      !isLoadingAvailableIssueManagerContexts &&
      isLoggedIn,
  );

  useMeilisearchApiKey(isLoggedIn && !isLoadingAvailableTenants);

  const { data: siteApplications } = useApplications(
    isLoggedIn && user.viewContext === 'site',
  );

  const hasAccessToIssuesManagement = siteApplications?.some(
    application => application.token === ApplicationToken.ISSUES,
  );

  const renderContent = () => {
    if (auth.loading) {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}>
          <Loader />
        </Box>
      );
    }

    return content;
  };

  return (
    <AbilityProvider
      viewContext={user.viewContext ?? 'site'}
      role={user?.role ?? UserRole.VIEWER}
      userToken={user?.adminToken ?? ''}
      hasAccessToIssuesManagement={Boolean(hasAccessToIssuesManagement)}>
      <Helmet>
        <title>{baseHeaderAppName}</title>
      </Helmet>

      {renderContent()}
    </AbilityProvider>
  );
}

export default App;
