import { Card, List, MenuItem, Popper } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { MutableRefObject } from 'react';
import { useNavigate } from 'react-router-dom';
import { issueManagerMeilisearchSearchKeyQueryKeys } from 'src/api/query-keys/issue-manager/meilisearch-search-key';
import { useAvailableIssueManagerContexts } from 'src/hooks/api/use-available-issue-manager-contexts';
import { useUserStore } from 'src/store/user-store';

interface Props {
  anchorRef: MutableRefObject<HTMLElement | null>;
  open: boolean;
  onClose: () => void;
  onMouseLeave?: () => void;
  onMouseEnter?: () => void;
  placement: 'bottom-end' | 'right-start';
}

export const AvailableIssueManagerContextsPopper = ({
  anchorRef,
  open,
  onClose,
  onMouseLeave,
  onMouseEnter,
  placement,
}: Props) => {
  const {
    setViewContext,
    setIsChangingDirectory,
    setDirectoryToken,
    clearAdminToken,
    clearRole,
  } = useUserStore();

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const { data: availableIssueManagerContexts } =
    useAvailableIssueManagerContexts();

  const handleChangeContext = (directoryToken: string) => {
    setViewContext('issue-manager');
    setIsChangingDirectory(true);
    setDirectoryToken(directoryToken);
    clearAdminToken();
    clearRole();

    onClose();

    queryClient.invalidateQueries({
      queryKey: issueManagerMeilisearchSearchKeyQueryKeys.all,
    });

    navigate('/');
  };

  return (
    <Popper
      anchorEl={anchorRef.current}
      open={open}
      placement={placement}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      sx={{
        margin: placement === 'bottom-end' ? '10px 0 !important' : undefined,
        top: placement === 'right-start' ? '-10px !important' : undefined,
      }}>
      <Card variant="outlined" sx={{ maxHeight: '90vh', overflowY: 'auto' }}>
        <List>
          {availableIssueManagerContexts?.map(context => (
            <MenuItem
              key={context.token}
              onClick={() => {
                handleChangeContext(context.token);
              }}>
              {context.name}
            </MenuItem>
          ))}
        </List>
      </Card>
    </Popper>
  );
};
