import {
  Box,
  Button,
  Card,
  List,
  MenuItem,
  Popper,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAvailableIssueManagerContexts } from 'src/hooks/api/use-available-issue-manager-contexts';
import { useAvailableSites } from 'src/hooks/api/use-available-sites';
import { useAvailableTenants } from 'src/hooks/api/use-available-tenants';
import { usePopover } from 'src/hooks/use-popover';
import { useViewContextName } from 'src/hooks/use-view-context-name';
import { Briefcase as BriefcaseIcon } from 'src/icons/briefcase';
import { BroomIcon } from 'src/icons/broom';
import { ChevronDown } from 'src/icons/chevron-down';
import { useUserStore } from 'src/store/user-store';
import { createOutsideClickHandler } from 'src/utils/create-outside-click-handler';
import { AvailableIssueManagerContextsPopper } from '../molecules/available-issue-manager-contexts-popper';
import { AvailableSitesPopper } from '../molecules/available-sites-popper';
import { AvailableTenantsPopper } from '../molecules/available-tenants-popper';

export const PanelContextSelector = () => {
  const { t } = useTranslation();

  const { viewContext } = useUserStore();

  const { data: availableTenants } = useAvailableTenants();

  const { data: availableSites } = useAvailableSites();

  const { data: availableIssueManagerContexts } =
    useAvailableIssueManagerContexts();

  const getViewContextName = useViewContextName();

  const {
    palette: { mode },
  } = useTheme();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );

  const [
    mainMenuAnchorRef,
    openMainMenu,
    handleOpenMainMenu,
    handleCloseMainMenu,
  ] = usePopover();

  const [
    tenantMenuAnchorRef,
    openTenantMenu,
    handleOpenTenantMenu,
    handleCloseTenantMenu,
  ] = usePopover<HTMLDivElement>();

  const [
    siteMenuAnchorRef,
    openSiteMenu,
    handleOpenSiteMenu,
    handleCloseSiteMenu,
  ] = usePopover<HTMLLIElement>();

  const [
    issueManagerMenuAnchorRef,
    openIssueManagerMenu,
    handleOpenIssueManagerMenu,
    handleCloseIssueManagerMenu,
  ] = usePopover<HTMLLIElement>();

  const handleCloseAllMenus = () => {
    handleCloseMainMenu();
    handleCloseTenantMenu();
    handleCloseSiteMenu();
    handleCloseIssueManagerMenu();
  };

  useEffect(() => {
    const excludedRefs = [
      mainMenuAnchorRef,
      tenantMenuAnchorRef,
      siteMenuAnchorRef,
      issueManagerMenuAnchorRef,
    ];

    createOutsideClickHandler(excludedRefs, handleCloseAllMenus);
  }, []);

  const ContextSelectorIcon =
    viewContext === 'issue-manager' ? BroomIcon : BriefcaseIcon;

  const hasAccessToTenantContext =
    availableTenants?.resources && availableTenants.resources.length >= 1;

  const hasAccessToSiteContext =
    availableSites?.resources && availableSites.resources.length >= 1;

  const hasAccessToIssueManagerContext =
    availableIssueManagerContexts && availableIssueManagerContexts.length >= 1;

  return (
    <>
      <Button
        color="primary"
        onClick={openMainMenu ? handleCloseMainMenu : handleOpenMainMenu}
        ref={mainMenuAnchorRef}
        size={isMobile ? 'medium' : 'large'}
        variant="text"
        startIcon={
          <ContextSelectorIcon
            fontSize="small"
            sx={{
              color: mode === 'dark' ? '#fff' : '#000',
              width: '16px',
            }}
          />
        }
        endIcon={<ChevronDown fontSize="small" />}
        sx={{
          ml: 1,
          mr: 2,
          alignItems: 'center',
          fontWeight: 400,
          color: 'text.primary',
          '&:hover': {
            color: 'text.primary',
          },
          '&:active': {
            color: 'text.primary',
          },
          '&:focus': {
            color: 'text.primary',
          },
        }}>
        {getViewContextName()}
      </Button>

      <Popper
        ref={tenantMenuAnchorRef}
        anchorEl={mainMenuAnchorRef.current}
        open={openMainMenu}
        placement="bottom-end"
        onMouseLeave={() => {
          handleCloseTenantMenu();
          handleCloseSiteMenu();
        }}
        sx={{ margin: '10px 0 !important' }}>
        <Card variant="outlined">
          <List>
            {hasAccessToTenantContext ? (
              <MenuItem
                onMouseEnter={() => {
                  handleOpenTenantMenu();
                  handleCloseSiteMenu();
                  handleCloseIssueManagerMenu();
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}>
                  {t('common:viewContextSelector.tenant')}

                  <ChevronDown
                    fontSize="small"
                    sx={{ ml: 2, transform: 'rotate(-90deg)' }}
                  />
                </Box>
              </MenuItem>
            ) : null}

            {hasAccessToSiteContext ? (
              <MenuItem
                ref={siteMenuAnchorRef}
                onMouseEnter={() => {
                  handleOpenSiteMenu();
                  handleCloseTenantMenu();
                  handleCloseIssueManagerMenu();
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}>
                  {t('common:viewContextSelector.site')}

                  <ChevronDown
                    fontSize="small"
                    sx={{ ml: 2, transform: 'rotate(-90deg)' }}
                  />
                </Box>
              </MenuItem>
            ) : null}

            {hasAccessToIssueManagerContext ? (
              <MenuItem
                ref={issueManagerMenuAnchorRef}
                onMouseEnter={() => {
                  handleOpenIssueManagerMenu();
                  handleCloseTenantMenu();
                  handleCloseSiteMenu();
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}>
                  {t('common:viewContextSelector.issueManager')}

                  <ChevronDown
                    fontSize="small"
                    sx={{ ml: 2, transform: 'rotate(-90deg)' }}
                  />
                </Box>
              </MenuItem>
            ) : null}
          </List>
        </Card>
      </Popper>

      <AvailableTenantsPopper
        anchorRef={tenantMenuAnchorRef}
        open={openTenantMenu}
        onClose={handleCloseAllMenus}
        onMouseLeave={handleCloseTenantMenu}
        onMouseEnter={handleOpenTenantMenu}
        placement="right-start"
      />

      <AvailableSitesPopper
        anchorRef={siteMenuAnchorRef}
        open={openSiteMenu}
        onClose={handleCloseAllMenus}
        onMouseLeave={handleCloseSiteMenu}
        onMouseEnter={handleOpenSiteMenu}
        placement="right-start"
      />

      <AvailableIssueManagerContextsPopper
        anchorRef={issueManagerMenuAnchorRef}
        open={openIssueManagerMenu}
        onClose={handleCloseAllMenus}
        onMouseLeave={handleCloseIssueManagerMenu}
        onMouseEnter={handleOpenIssueManagerMenu}
        placement="right-start"
      />
    </>
  );
};
