export const IssuesIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 8.05761V11.168M10 14.2784H10.0078M8.92069 4.08542L2.50966 15.1325C2.15406 15.7452 1.97627 16.0516 2.00254 16.303C2.02547 16.5224 2.14065 16.7217 2.31942 16.8513C2.52439 17 2.87925 17 3.58898 17H16.411C17.1207 17 17.4756 17 17.6806 16.8513C17.8594 16.7217 17.9745 16.5224 17.9975 16.303C18.0237 16.0516 17.8459 15.7452 17.4903 15.1325L11.0793 4.08542C10.725 3.47488 10.5478 3.1696 10.3167 3.06708C10.1151 2.97764 9.88492 2.97764 9.6833 3.06708C9.45217 3.1696 9.27501 3.47488 8.92069 4.08542Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
