import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';

import { Box, Drawer, List, Typography } from '@mui/material';
import { matchPath, useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
// import { SiteSelector } from "./SiteSelector";
import { ViewContext } from 'src/store/user-store';
import { Route } from 'src/types/interfaces/route';
import { getRoutes } from 'src/view-contexts/tenant/constants';
import { NavbarMenuItem } from '../molecules/navbar-menu/navbar-menu-item';

interface NavbarMenuProps {
  onClose: () => void;
  open: boolean;
}

const getRoutesForViewContext = (currentViewContext: ViewContext) => {
  switch (currentViewContext) {
    // case "site":
    //   return getSiteRoutes(isLegacyAgreement);

    case 'tenant':
      return getRoutes();
  }
};

const NavbarMenuComponent: FC<NavbarMenuProps> = props => {
  const { t } = useTranslation();

  const { open, onClose } = props;
  const { pathname } = useLocation();

  // const { data } = useAdminsProfile();

  // const { data: siteAdminProfile } = useSiteAdminsProfile();

  const sections = useMemo(() => getRoutesForViewContext('tenant'), []);

  const routes = useMemo(
    () => sections?.flatMap(route => route.routes),
    [sections],
  );

  const [activeRoute, setActiveRoute] = useState<Route | null>(null);

  useEffect(() => {
    routes?.forEach(route => {
      const active = matchPath(
        { path: route.href ?? '', end: false },
        pathname,
      );

      if (active) {
        setActiveRoute(route);
      }
    });
  }, [routes, pathname]);

  return (
    <Drawer
      anchor="top"
      onClose={onClose}
      open={open}
      transitionDuration={0}
      ModalProps={{
        BackdropProps: {
          invisible: true,
        },
      }}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          color: '#B2B7C8',
          display: 'flex',
          flexDirection: 'column',
          top: 64,
          maxHeight: 'calc(100% - 64px)',
          width: '100vw',
        },
      }}>
      <List>
        {sections?.map(section => (
          <Box key={section.title}>
            <Typography
              sx={{
                fontSize: '14px',
                my: 1,
                px: 1,
              }}
              color="#98a2b3">
              {t(section.title) as string}
            </Typography>
            {section.routes.map((route: Route) => (
              <NavbarMenuItem
                key={route.title}
                active={activeRoute?.title === route.title}
                href={route.href ?? ''}
                onClose={onClose}
                {...route}
              />
            ))}
          </Box>
        ))}
      </List>
    </Drawer>
  );
};

export const NavbarMenu = NavbarMenuComponent;
