import { MutableRefObject } from 'react';

export const createOutsideClickHandler = (
  excludedRefs: MutableRefObject<HTMLElement | null>[],
  callbackFn: () => void,
  enabled: boolean = true,
) => {
  const handleClick = (event: MouseEvent) => {
    if (!enabled) return;

    let currentElement: Node | null = event.target as Node;

    while (currentElement) {
      if (
        excludedRefs.some(
          ref => ref.current && ref.current.contains(currentElement),
        )
      ) {
        return;
      }

      currentElement = currentElement.parentNode;
    }

    callbackFn();
  };

  document.addEventListener('click', handleClick);

  return () => document.removeEventListener('click', handleClick);
};
