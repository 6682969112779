import { useEffect } from 'react';
import { useUserStore } from 'src/store/user-store';
import { useAvailableIssueManagerContexts } from './api/use-available-issue-manager-contexts';
import { useAvailableSites } from './api/use-available-sites';
import { useAvailableTenants } from './api/use-available-tenants';

export const useSetInitialOrganizations = (isLoggedIn: boolean = true) => {
  const {
    directoryToken,
    setDirectoryToken,
    setAdminToken,
    setRole,
    viewContext,
  } = useUserStore();

  const { data: availableTenantsData, isLoading: isLoadingAvailableTenants } =
    useAvailableTenants(isLoggedIn);

  const { data: availableSitesData, isLoading: isLoadingAvailableSites } =
    useAvailableSites(isLoggedIn);

  const {
    data: availableIssueManagerContexts,
    isLoading: isLoadingAvailableIssueManagerContexts,
  } = useAvailableIssueManagerContexts(isLoggedIn);

  const hasAvailableTenants =
    availableTenantsData && availableTenantsData?.resources.length >= 1;

  const hasAvailableSites =
    availableSitesData && availableSitesData?.resources.length >= 1;

  const hasAvailableIssueManagerContexts =
    availableIssueManagerContexts && availableIssueManagerContexts?.length >= 1;

  useEffect(() => {
    if (viewContext === 'tenant') {
      if (hasAvailableTenants && !directoryToken) {
        setDirectoryToken(availableTenantsData?.resources?.[0]?.token);
      }
    }

    if (viewContext === 'site') {
      if (hasAvailableSites && !directoryToken) {
        setDirectoryToken(availableSitesData?.resources?.[0]?.token);
      }
    }

    if (viewContext === 'issue-manager') {
      if (hasAvailableIssueManagerContexts && !directoryToken) {
        setDirectoryToken(availableIssueManagerContexts?.[0]?.token);
      }
    }
  }, [
    availableSitesData?.resources,
    availableTenantsData?.resources,
    availableIssueManagerContexts,
    hasAvailableSites,
    hasAvailableTenants,
    hasAvailableIssueManagerContexts,
    setDirectoryToken,
    directoryToken,
    viewContext,
  ]);

  useEffect(() => {
    if (viewContext === 'tenant') {
      if (hasAvailableTenants && isLoggedIn && !isLoadingAvailableTenants) {
        setRole(availableTenantsData?.resources?.[0]?.role);
        setAdminToken(availableTenantsData?.resources?.[0]?.tenantAdminToken);
      }
    }

    if (viewContext === 'site') {
      if (hasAvailableSites && isLoggedIn && !isLoadingAvailableSites) {
        setRole(availableSitesData?.resources?.[0]?.role);
        setAdminToken(availableSitesData?.resources?.[0]?.siteAdminToken);
      }
    }
  }, [
    hasAvailableTenants,
    availableTenantsData?.resources,
    isLoadingAvailableTenants,
    isLoggedIn,
    viewContext,
    setRole,
    setAdminToken,
    hasAvailableSites,
    isLoadingAvailableSites,
    availableSitesData?.resources,
  ]);

  return {
    isLoadingAvailableTenants,
    isLoadingAvailableSites,
    isLoadingAvailableIssueManagerContexts,
  };
};
