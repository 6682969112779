import { useTranslation } from 'react-i18next';
import { useUserStore } from 'src/store/user-store';
import { useAvailableIssueManagerContexts } from './api/use-available-issue-manager-contexts';
import { useAvailableSites } from './api/use-available-sites';
import { useAvailableTenants } from './api/use-available-tenants';

export const useViewContextName = () => {
  const { t } = useTranslation();

  const { viewContext, directoryToken } = useUserStore();

  const { data: availableTenants } = useAvailableTenants();

  const selectedTenant = availableTenants?.resources.find(
    tenant => tenant.token === directoryToken,
  );

  const { data: availableSites } = useAvailableSites();

  const selectedSite = availableSites?.resources.find(
    site => site.token === directoryToken,
  );

  const { data: availableIssueManagerContexts } =
    useAvailableIssueManagerContexts();

  const selectedIssueManagerContext = availableIssueManagerContexts?.find(
    context => context.token === directoryToken,
  );

  const getViewContextName = () => {
    switch (viewContext) {
      case 'site':
        return t('common:viewContextSelector.siteWithName', {
          siteName: selectedSite?.name,
        });

      case 'tenant':
        return t('common:viewContextSelector.tenantWithName', {
          tenantName: selectedTenant?.name,
        });

      case 'issue-manager':
        return t('common:viewContextSelector.issueManagerWithName', {
          siteName: selectedIssueManagerContext?.name,
        });

      default:
        return t('common:viewContextSelector.placeholder');
    }
  };

  return getViewContextName;
};
