import { useQuery } from '@tanstack/react-query';
import { generateSiteSearchKey } from 'src/api/lib/meilisearch';
import { siteMeilisearchSearchKeyKeys } from 'src/api/query-keys/site/meilisearch-search-key';
import { useUserStore } from 'src/store/user-store';

export const useGenerateSiteSearchKey = (enabled = true) => {
  const { directoryToken } = useUserStore();

  return useQuery({
    queryFn: generateSiteSearchKey,
    queryKey: siteMeilisearchSearchKeyKeys.detail(directoryToken ?? ''),
    enabled,
  });
};
