import { Card, List, MenuItem, Popper } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { MutableRefObject } from 'react';
import { useNavigate } from 'react-router-dom';
import { tenantMeilisearchSearchKeyKeys } from 'src/api/query-keys/tenant/meilisearch-search-key';
import { useAvailableTenants } from 'src/hooks/api/use-available-tenants';
import { useUserStore } from 'src/store/user-store';
import { UserRole } from 'src/types/enum/user-role';

interface Props {
  anchorRef: MutableRefObject<HTMLElement | null>;
  open: boolean;
  onClose: () => void;
  onMouseLeave?: () => void;
  onMouseEnter?: () => void;
  placement: 'bottom-end' | 'right-start';
}

export const AvailableTenantsPopper = ({
  anchorRef,
  open,
  onClose,
  onMouseLeave,
  onMouseEnter,
  placement,
}: Props) => {
  const {
    setViewContext,
    setIsChangingDirectory,
    setDirectoryToken,
    setAdminToken,
    setRole,
  } = useUserStore();

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const { data: availableTenants } = useAvailableTenants();

  const handleChangeContext = (
    directoryToken: string,
    adminToken: string,
    role: UserRole,
  ) => {
    setViewContext('tenant');
    setIsChangingDirectory(true);
    setDirectoryToken(directoryToken);
    setAdminToken(adminToken);
    setRole(role);

    onClose();

    queryClient.invalidateQueries({
      queryKey: tenantMeilisearchSearchKeyKeys.details(),
    });

    navigate('/');
  };

  return (
    <Popper
      anchorEl={anchorRef.current}
      open={open}
      placement={placement}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      sx={{
        margin: placement === 'bottom-end' ? '10px 0 !important' : '',
      }}>
      <Card variant="outlined" sx={{ maxHeight: '90vh', overflowY: 'auto' }}>
        <List>
          {availableTenants?.resources?.map(tenant => (
            <MenuItem
              key={tenant.token}
              onClick={() => {
                handleChangeContext(
                  tenant.token,
                  tenant.tenantAdminToken,
                  tenant.role,
                );
              }}>
              {tenant.name}
            </MenuItem>
          ))}
        </List>
      </Card>
    </Popper>
  );
};
