import { QueryKey, keepPreviousData, useQuery } from '@tanstack/react-query';
import { useUserStore } from 'src/store/user-store';
import { ApiError } from 'src/types/interfaces/api-error';
import { MeilisearchError } from 'src/types/interfaces/meilisearch-error';
import { MeiliSearchResponse } from 'src/types/responses/meilisearch';
import { SearchState } from '../../types/interfaces/search-state';
import { MEILISEARCH_ERRORS_CODES_TO_IGNORE } from './constants';

export interface useMeilisearchReturn<TData> {
  results: MeiliSearchResponse<TData> | undefined;
  isLoading: boolean;
  error: ApiError | null;
  isFetching: boolean;
}

const defaultSearchState: SearchState = {
  filter: [],
  q: '',
  sort: [],
};

interface MeilisearchPayload<TData, TQueryKey> {
  fetch: (
    tenantToken: string,
    searchState: SearchState,
    page: number,
    perPage: number,
  ) => Promise<MeiliSearchResponse<TData>>;
  page: number;
  perPage: number;
  queryKey?: TQueryKey;
  searchState?: SearchState;
  enabled?: boolean;
}

export function useMeilisearch<TData, TQueryKey extends QueryKey = QueryKey>({
  fetch,
  page,
  perPage,
  queryKey,
  searchState,
  enabled = true,
}: MeilisearchPayload<TData, TQueryKey>): useMeilisearchReturn<TData> {
  const user = useUserStore();

  const { data, isLoading, error, isFetching } = useQuery<
    MeiliSearchResponse<TData>,
    MeilisearchError
  >({
    queryKey: [...(queryKey ?? '')],
    queryFn: () =>
      fetch(
        user.directoryToken ?? '',
        searchState ?? defaultSearchState,
        page,
        perPage,
      ),
    placeholderData: keepPreviousData,
    enabled: !!user.meilisearchToken && enabled,
  });

  return {
    results: data,
    isFetching,
    isLoading,
    error: shouldIgnoreError(error),
  };
}

const shouldIgnoreError = (error: MeilisearchError | null) => {
  if (!error) {
    return error;
  }

  const shouldIgnore = MEILISEARCH_ERRORS_CODES_TO_IGNORE.includes(
    error.response?.data?.code,
  );

  if (shouldIgnore) {
    return null;
  }

  return error;
};
