import { ComponentType, Suspense } from 'react';
import { LoadingScreen } from './loading-screen';

export const Loadable =
  <T extends object>(Component: ComponentType<T>): ComponentType<T> =>
  props => (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
