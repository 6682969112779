import { Button, Theme, useMediaQuery, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { usePopover } from 'src/hooks/use-popover';
import { useViewContextName } from 'src/hooks/use-view-context-name';
import { Briefcase as BriefcaseIcon } from 'src/icons/briefcase';
import { ChevronDown } from 'src/icons/chevron-down';
import { createOutsideClickHandler } from 'src/utils/create-outside-click-handler';
import { AvailableTenantsPopper } from '../molecules/available-tenants-popper';

export const TenantSelector = () => {
  const getViewContextName = useViewContextName();

  const {
    palette: { mode },
  } = useTheme();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );

  const [
    tenantMenuAnchorRef,
    openTenantMenu,
    handleOpenTenantMenu,
    handleCloseTenantMenu,
  ] = usePopover();

  useEffect(() => {
    createOutsideClickHandler([tenantMenuAnchorRef], handleCloseTenantMenu);
  }, []);

  return (
    <>
      <Button
        color="primary"
        onClick={openTenantMenu ? handleCloseTenantMenu : handleOpenTenantMenu}
        ref={tenantMenuAnchorRef}
        size={isMobile ? 'medium' : 'large'}
        variant="text"
        startIcon={
          <BriefcaseIcon
            fontSize="small"
            sx={{
              color: mode === 'dark' ? '#fff' : '#000',
              width: '16px',
            }}
          />
        }
        endIcon={<ChevronDown fontSize="small" />}
        sx={{
          ml: 1,
          mr: 2,
          alignItems: 'center',
          fontWeight: 400,
          color: 'text.primary',
          '&:hover': {
            color: 'text.primary',
          },
          '&:active': {
            color: 'text.primary',
          },
          '&:focus': {
            color: 'text.primary',
          },
        }}>
        {getViewContextName()}
      </Button>

      <AvailableTenantsPopper
        anchorRef={tenantMenuAnchorRef}
        open={openTenantMenu}
        onClose={handleCloseTenantMenu}
        placement="bottom-end"
      />
    </>
  );
};
