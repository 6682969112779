import { useQuery } from '@tanstack/react-query';

import { tenantMeilisearchSearchKeyKeys } from 'src/api/query-keys/tenant/meilisearch-search-key';
import { useUserStore } from 'src/store/user-store';
import { generateTenantSearchKey } from '../../features/tenant/api/lib/meilisearch';

export const useGenerateTenantSearchKey = (enabled = true) => {
  const { directoryToken } = useUserStore();

  return useQuery({
    queryFn: generateTenantSearchKey,
    queryKey: tenantMeilisearchSearchKeyKeys.detail(directoryToken ?? ''),
    enabled,
  });
};
