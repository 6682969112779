import { useEffect } from 'react';
import { useGenerateTenantSearchKey } from 'src/hooks/api/use-generate-tenant-search-key';
import { useUserStore } from 'src/store/user-store';
import { useGenerateIssueManagerSearchKey } from './use-generate-issue-manager-search-key';
import { useGenerateSiteSearchKey } from './use-generate-site-search-key';

export const useMeilisearchApiKey = (enabled: boolean = true) => {
  const meilisearchToken = useUserStore().meilisearchToken;
  const setMeilisearchToken = useUserStore().setMeilisearchToken;
  const viewContext = useUserStore().viewContext;

  const { data: newMeilisearchTenantToken } = useGenerateTenantSearchKey(
    viewContext === 'tenant' && enabled,
  );

  const { data: newMeilisearchSiteToken } = useGenerateSiteSearchKey(
    viewContext === 'site' && enabled,
  );

  const { data: newMeilisearchIssueManagerToken } =
    useGenerateIssueManagerSearchKey(
      viewContext === 'issue-manager' && enabled,
    );

  useEffect(() => {
    if (viewContext === 'tenant') {
      if (
        newMeilisearchTenantToken &&
        newMeilisearchTenantToken !== meilisearchToken
      ) {
        setMeilisearchToken(newMeilisearchTenantToken);
      }
    }

    if (viewContext === 'site') {
      if (
        newMeilisearchSiteToken &&
        newMeilisearchSiteToken !== meilisearchToken
      ) {
        setMeilisearchToken(newMeilisearchSiteToken);
      }
    }

    if (viewContext === 'issue-manager') {
      if (
        newMeilisearchIssueManagerToken &&
        newMeilisearchIssueManagerToken !== meilisearchToken
      ) {
        setMeilisearchToken(newMeilisearchIssueManagerToken);
      }
    }
  }, [
    meilisearchToken,
    newMeilisearchSiteToken,
    newMeilisearchTenantToken,
    newMeilisearchIssueManagerToken,
    setMeilisearchToken,
    viewContext,
  ]);
};
