import axios from 'axios';
import { attachAcceptLanguage } from 'src/api/interceptors/attach-accept-language';
import { attachAccessToken } from 'src/api/interceptors/attach-access-token';
import { attachDirectoryToken } from '../../../../../api/interceptors/attach-directory-token';

const issueManagerApi = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}/issues-manager/v1/contexts`,
});

issueManagerApi.interceptors.request.use(attachAcceptLanguage);
issueManagerApi.interceptors.request.use(attachAccessToken);
issueManagerApi.interceptors.request.use(attachDirectoryToken);

export default issueManagerApi;
