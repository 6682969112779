import siteApiV2 from 'src/api/clients/site-v2';
import { Application } from '../../types/application';

export const fetchApplications = async (): Promise<Application[]> => {
  const { data } = await siteApiV2.get<{ applications: Application[] }>(
    '/applications',
  );

  return data.applications;
};

export const updateDefaultApplications = async (
  applicationsTokens: string[],
): Promise<void> => {
  await siteApiV2.put('/tenants/applications/default', {
    applicationsTokens,
  });
};

export const grantApplicationsToTenant = async (
  tenantsTokens: string[],
  applicationsTokens: string[],
): Promise<void> => {
  await siteApiV2.patch('/tenants/applications', {
    applicationsTokens,
    tenantsTokens,
  });
};

export const detachApplicationsFromTenant = async (
  tenantsTokens: string[],
  applicationsTokens: string[],
): Promise<void> => {
  await siteApiV2.post('/tenants/detach-applications', {
    applicationsTokens,
    tenantsTokens,
  });
};
