export enum RouteTitle {
  PUBLICATIONS = 'routes:publications',
  INVITATION_SETTINGS = 'invitationSettings:title',
  PASSWORD = 'password:changePassword:title',
  CONTACT_FORM = 'tenantContactForm:contactForm.title',
  EMPLOYEES = 'tenantEmployees:employees.title',
  ORGANIZATION_DETAILS = 'organizationDetails:title',
  MOBILE_APP_MANAGEMENT = 'routes:mobileAppManagement',
  TENANTS = 'routes:tenants',
  SITE_MANAGEMENT = 'routes:siteManagement',
  SITE_DETAILS = 'routes:siteDetails',
  PLACES = 'sitePlaces:places.title',
  ENVIRONMENTAL_INDICATORS = 'siteEnvironmentalIndicators:indicators.title',
  POLLS = 'tenantPolls:polls.title',
  POSTS = 'tenantPosts:posts.tab',
  EVENTS = 'tenantEvents:events.tab',
  MESSAGES = 'messages:tab.title',
  FAQS = 'siteFaqs:tab.title',
  SURVEYS = 'tenantPolls:polls.title',
  ARTICLES = 'tenantPosts:posts.tab',
  AUDIT_LOGS = 'auditLogs:common.route',
  ISSUES = 'siteIssues:common.title',
}

export enum SidebarRouteTitle {
  SETUP = 'routes:setup',
  PROFILE = 'routes:profile',
  SUPPORT_AND_SETTINGS = 'routes:supportAndSettings',
  PUBLICATIONS = 'routes:publications',
}

export enum SubRouteTitle {}
