import { useQuery } from '@tanstack/react-query';
import { fetchAvailableIssueManagerContexts } from 'src/api/lib/issue-manager';
import { availableIssueManagerContextsQueryKeys } from 'src/api/query-keys/issue-manager/available-issue-manager-contexts';
import { ApiError } from 'src/types/interfaces/api-error';
import { AvailableIssueManagerContext } from 'src/types/responses/available-issue-manager-context';

export const useAvailableIssueManagerContexts = (enabled: boolean = true) => {
  return useQuery<AvailableIssueManagerContext[], ApiError>({
    queryFn: fetchAvailableIssueManagerContexts,
    queryKey: availableIssueManagerContextsQueryKeys.all,
    enabled,
    refetchOnWindowFocus: false,
    retry: false,
  });
};
