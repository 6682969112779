import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'src/containers/contexts/auth-provider';
import { useUserStore } from 'src/store/user-store';
import { useAvailableIssueManagerContexts } from './api/use-available-issue-manager-contexts';
import { useAvailableSites } from './api/use-available-sites';
import { useAvailableTenants } from './api/use-available-tenants';

type ReturnType = {
  tenantContext: boolean;
  siteContext: boolean;
  issueManagerContext: boolean;
};

export const useSetViewContext = (): ReturnType => {
  const { viewContext, setViewContext, setIsLoadingViewContextAccess } =
    useUserStore();

  const auth = useAuth();
  const navigate = useNavigate();

  const [access, setAccess] = useState({
    tenantContext: false,
    siteContext: false,
    issueManagerContext: false,
  });

  const [hasFetchedAccess, setHasFetchedAccess] = useState(false);

  const availableTenants = useAvailableTenants(!!auth.session?.access_token);

  useEffect(() => {
    if (
      availableTenants.isSuccess &&
      availableTenants.data?.resources.length > 0
    ) {
      setAccess(prev => ({
        ...prev,
        tenantContext: true,
      }));
    }

    if (
      availableTenants.isError ||
      (!availableTenants.isPending &&
        availableTenants.data?.resources.length === 0)
    ) {
      setAccess(prev => ({
        ...prev,
        tenantContext: false,
      }));
    }
  }, [
    availableTenants.isSuccess,
    availableTenants.isError,
    availableTenants.data?.resources.length,
    availableTenants.isPending,
  ]);

  const availableSites = useAvailableSites(!!auth.session?.access_token);

  useEffect(() => {
    if (availableSites.isSuccess && availableSites.data?.resources.length > 0) {
      setAccess(prev => ({
        ...prev,
        siteContext: true,
      }));
    }

    if (
      availableSites.isError ||
      (!availableSites.isPending && availableSites.data?.resources.length === 0)
    ) {
      setAccess(prev => ({
        ...prev,
        siteContext: false,
      }));
    }
  }, [
    availableSites.isSuccess,
    availableSites.isError,
    availableSites.data?.resources.length,
    availableSites.isPending,
  ]);

  const availableIssueManagerContexts = useAvailableIssueManagerContexts(
    !!auth.session?.access_token,
  );

  useEffect(() => {
    if (
      availableIssueManagerContexts.isSuccess &&
      availableIssueManagerContexts.data?.length > 0
    ) {
      setAccess(prev => ({
        ...prev,
        issueManagerContext: true,
      }));
    }

    if (
      availableIssueManagerContexts.isError ||
      (!availableIssueManagerContexts.isPending &&
        availableIssueManagerContexts.data?.length === 0)
    ) {
      setAccess(prev => ({
        ...prev,
        issueManagerContext: false,
      }));
    }
  }, [
    availableIssueManagerContexts.isSuccess,
    availableIssueManagerContexts.isError,
    availableIssueManagerContexts.data?.length,
    availableIssueManagerContexts.isPending,
  ]);

  useEffect(() => {
    if (
      (availableTenants.isSuccess || availableTenants.isError) &&
      (availableSites.isSuccess || availableSites.isError) &&
      (availableIssueManagerContexts.isSuccess ||
        availableIssueManagerContexts.isError)
    ) {
      setHasFetchedAccess(true);
    }
  }, [
    availableSites.isError,
    availableSites.isSuccess,
    availableTenants.isError,
    availableTenants.isSuccess,
    availableIssueManagerContexts.isError,
    availableIssueManagerContexts.isSuccess,
  ]);

  useEffect(() => {
    if (hasFetchedAccess) {
      switch (true) {
        case access.siteContext && viewContext === null:
          setViewContext('site');
          setIsLoadingViewContextAccess(false);

          navigate('/');

          break;

        case access.tenantContext && viewContext === null:
          setViewContext('tenant');
          setIsLoadingViewContextAccess(false);

          navigate('/');

          break;

        case access.issueManagerContext && viewContext === null:
          setViewContext('issue-manager');
          setIsLoadingViewContextAccess(false);

          navigate('/');

          break;

        default:
          setIsLoadingViewContextAccess(false);
          break;
      }
    }
  }, [
    access.siteContext,
    access.tenantContext,
    access.issueManagerContext,
    navigate,
    setIsLoadingViewContextAccess,
    setViewContext,
    viewContext,
    hasFetchedAccess,
  ]);

  return access;
};
