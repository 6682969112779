import { AbilityBuilder } from '@casl/ability';
import { AppAbility } from './define-permissions';

export const issueManagerPermissions = (
  builder: AbilityBuilder<AppAbility>,
) => {
  const { can } = builder;

  can('read', 'IssuesManagement');
  can('downloadXls', 'IssuesManagement');
  can('editStatus', 'IssuesManagement');
};
