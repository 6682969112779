import { lazy } from 'react';
import { Navigate, type DataRouteObject } from 'react-router';
import { AuthGuard } from 'src/components/guards/auth-guard';
import { Loadable } from 'src/components/loadable';
import { Layout } from 'src/layout/main-layout';

const IssuesContainer = Loadable(
  lazy(() =>
    import(
      '../../features/issues-management/issue-manager/containers/issues'
    ).then(module => ({
      default: module.IssuesContainer,
    })),
  ),
);

const IssueDetailsContainer = Loadable(
  lazy(() =>
    import(
      '../../features/issues-management/issue-manager/containers/issue-details'
    ).then(module => ({
      default: module.IssueDetailsContainer,
    })),
  ),
);

export const issueManagerRoutes: DataRouteObject[] = [
  {
    path: '/',
    id: 'issues',
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        id: '/issues',
        path: '/',
        element: <Navigate to="/issues" replace />,
      },
    ],
  },
  {
    id: 'issues-container',
    path: 'issues',
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        id: 'issues',
        index: true,
        element: <IssuesContainer />,
      },
      {
        id: 'issue-details',
        path: '/issues/:issueToken',
        element: <IssueDetailsContainer />,
      },
    ],
  },
];
