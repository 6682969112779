import issueManagerApi from 'src/features/issues-management/shared/api/clients/issue-manager';
import { AvailableIssueManagerContext } from 'src/types/responses/available-issue-manager-context';

export const fetchAvailableIssueManagerContexts = async (): Promise<
  AvailableIssueManagerContext[]
> => {
  const { data } = await issueManagerApi.get<AvailableIssueManagerContext[]>(
    '',
    {
      skipTenantAttachment: true,
    },
  );

  return data;
};

export const acceptIssueManagerInvitation = async (
  issueManagerToken: string,
): Promise<void> => {
  await issueManagerApi.post<void>(`/invitations/${issueManagerToken}/accept`);
};

export const generateIssueManagerSearchKey = async (): Promise<string> => {
  const { data } = await issueManagerApi.get<string>('/generate-search-key');

  return data;
};
