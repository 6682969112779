import { createSvgIcon } from '@mui/material';

export const BroomIcon = createSvgIcon(
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6099_41701)">
      <path
        d="M14 1L10.1224 4.71694M10.1224 4.71694L9.45036 4.05753C8.99856 3.61421 8.30552 3.52919 7.75996 3.85013L7.55655 3.9698L10.8612 7.20998L10.9173 7.1294C11.3072 6.56873 11.2357 5.80852 10.7481 5.3304L10.1224 4.71694ZM5.54765 5.09654C4.28293 5.50988 3.01821 5.92337 1.20289 5.28952C1.10716 5.25609 1.00629 5.32937 1.01469 5.43042C1.27233 8.53138 3.5057 11.4916 6.70738 12.8377C6.95662 12.9425 7.24422 12.8813 7.42892 12.6838C8.08152 11.9862 8.80837 10.9883 9.56126 9.14286L5.54765 5.09654Z"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6099_41701">
        <rect width="14" height="14" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>,
  'Broom',
);
