import { Box, Button, Drawer, List, Tooltip } from '@mui/material';
import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { ApplicationToken } from 'src/features/applications/types/enums/application-token';
import { useApplications } from 'src/features/site/hooks/use-applications';
import { Plus } from 'src/icons/plus';
import { Can } from 'src/permissions/can';
import { ViewContext, useUserStore } from 'src/store/user-store';
import { Route } from 'src/types/interfaces/route';
import { getRoutes as siteRoutes } from 'src/view-contexts/site/constants';
import { getRoutes as tenantRoutes } from 'src/view-contexts/tenant/constants';
import { Scrollbar } from '../atoms/scrollbar';
import SidebarItems from '../molecules/sidebar/sidebar-items';

interface SidebarProps {
  onPin?: () => void;
  pinned: boolean;
}

const getRoutesForViewContext = (
  currentViewContext: ViewContext,
  hasAccessToIssuesManagement: boolean,
) => {
  switch (currentViewContext) {
    case 'tenant':
      return tenantRoutes();

    case 'site':
      return siteRoutes(hasAccessToIssuesManagement);
  }
};

const SidebarComponent: FC<SidebarProps> = props => {
  const { t } = useTranslation();
  const { pinned } = props;
  const { pathname } = useLocation();
  const { viewContext } = useUserStore();
  const navigate = useNavigate();

  const { data: siteApplications } = useApplications(viewContext === 'site');

  const hasAccessToIssuesManagement = siteApplications?.some(
    application => application.token === ApplicationToken.ISSUES,
  );

  const sections = useMemo(
    () =>
      getRoutesForViewContext(
        viewContext ?? 'tenant',
        Boolean(hasAccessToIssuesManagement),
      ) ?? [],
    [viewContext, hasAccessToIssuesManagement],
  );
  const routes = useMemo(
    () => sections?.flatMap(route => route.routes),
    [sections],
  );

  const [activeRoute, setActiveRoute] = useState<Route | null>(null);

  useEffect(() => {
    routes?.forEach(route => {
      const active = matchPath(
        { path: route.href ?? '', end: false },
        pathname,
      );

      if (active) {
        setActiveRoute(route);
      }
    });
  }, [pathname, routes]);

  return (
    <>
      <Drawer
        open
        variant="permanent"
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px)',
            overflowX: 'hidden',
            top: 64,
            zIndex: 1,
            transition: 'width 250ms ease-in-out',
            width: pinned ? 270 : 73,
            '& .simplebar-content': {
              height: '100%',
            },
            '&:hover': {
              width: 270,
              '& span, p': {
                display: 'flex',
              },
            },
          },
        }}>
        <Scrollbar
          style={{
            display: 'flex',
            flex: 1,
            overflowX: 'hidden',
            overflowY: 'auto',
          }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              p: 2,
              pt: 2.5,
              pb: 7,
            }}>
            <Can I="create" a="Site" passThrough>
              {allowed =>
                allowed ? (
                  <Tooltip
                    title={t('common:notSufficientPermissions')}
                    disableHoverListener={allowed}
                    disableFocusListener={allowed}>
                    <span>
                      <Box sx={{ my: 1 }}>
                        <Button
                          sx={{
                            color: '#6EBAD3',
                            borderBottom: '1px solid #6EBAD3',
                            borderRadius: 0,
                          }}
                          variant="text"
                          disabled={!allowed}
                          onClick={() => navigate('/create-site')}
                          startIcon={<Plus />}>
                          {t('createSite:general.actions.addNewSite')}
                        </Button>
                      </Box>
                    </span>
                  </Tooltip>
                ) : null
              }
            </Can>

            <List
              disablePadding
              sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
              {sections.map(section => (
                <SidebarItems
                  key={section.title}
                  title={section.title}
                  routes={section.routes}
                  activeRoute={activeRoute}
                  pinned={pinned}
                />
              ))}
            </List>
          </Box>
        </Scrollbar>
      </Drawer>
    </>
  );
};

export const Sidebar = SidebarComponent;
