import type { MutableRefObject } from 'react';
import { useRef, useState } from 'react';

export const usePopover = <T extends HTMLElement = HTMLButtonElement>(): [
  MutableRefObject<T | null>,
  boolean,
  () => void,
  () => void,
] => {
  const anchorRef = useRef<T | null>(null);
  const [open, setOpen] = useState(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return [anchorRef, open, handleOpen, handleClose];
};
