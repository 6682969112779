import {
  AbilityBuilder,
  MongoAbility,
  createMongoAbility,
} from '@casl/ability';
import { ViewContext } from 'src/store/user-store';
import { issueManagerPermissions } from './issue-manager-permissions';
import { siteContextPermissions } from './site-context-permissions';
import { tenantContextPermissions } from './tenant-context-permissions';

type CRUD = 'create' | 'read' | 'update' | 'delete';
type SUBJECTS =
  | 'OrganizationDetails'
  | 'TenantDomains'
  | 'TenantEmployeeJoinMethod'
  | 'TenantPassword'
  | 'TenantContactForm'
  | 'TenantEvents'
  | 'SiteReceptionDetails'
  | 'SitePolls'
  | 'TenantPolls'
  | 'SiteIndicators'
  | 'SiteIndicatorTypes'
  | 'SiteIndicatorsComparison'
  | 'Site'
  | 'TenantPermissions'
  | 'SiteAssets'
  | 'SiteGdpr'
  | 'Password'
  | 'TenantSiteDetails'
  | 'TenantReceptionDetails'
  | 'TenantAuditLogs'
  | 'SiteAuditLogs';

type Abilities = [CRUD, SUBJECTS];

interface TenantAdministrator {
  userToken: string;
}

type TenantEmployeesActions = CRUD | 'upload' | 'resendInvitation';

type TenantEmployeesAbilities = [TenantEmployeesActions, 'TenantEmployees'];

type TenantEmployeesAccessActions = CRUD | 'grant' | 'revoke';

type TenantEmployeesAccessAbilities = [
  TenantEmployeesAccessActions,
  'TenantEmployeesAccess',
];

type TenantEmployeesDisplayedApplicationsActions = CRUD | 'stopDisplaying';

type TenantEmployeesDisplayedApplicationsAbilities = [
  TenantEmployeesDisplayedApplicationsActions,
  'TenantEmployeesDisplayedApplications',
];

type TenantEmployeesJoinRequestActions = CRUD | 'approve' | 'reject';

type TenantEmployeesJoinRequestAbilities = [
  TenantEmployeesJoinRequestActions,
  'TenantEmployeesJoinRequest',
];

type TenantAdministratorsActions =
  | CRUD
  | 'updatePhoneNumber'
  | 'updateRole'
  | 'resendInvitation';

type TenantAdministratorsAbilities = [
  TenantAdministratorsActions,
  'TenantAdministrators' | TenantAdministrator,
];

type TenantApplicationsActions = CRUD | 'setAsDefault' | 'removeFromDefault';

type TenantApplicationsAbilities = [
  TenantApplicationsActions,
  'TenantApplications' | TenantAdministrator,
];

type SiteTenantsActions = CRUD | 'resendInvitation';

type SiteTenantsAbilities = [SiteTenantsActions, 'SiteTenants'];

type SiteAdministratorsActions =
  | CRUD
  | 'resendInvitation'
  | 'updatePhoneNumber'
  | 'updateRole';

type SiteAdministratorsAbilities = [
  SiteAdministratorsActions,
  'SiteAdministrators',
];

type SiteEventsActions = CRUD;

type SiteEventsAbilities = [SiteEventsActions, 'SiteEvents'];

type TenantPostsActions = CRUD | 'publish' | 'unpublish';

type TenantPostsAbilities = [TenantPostsActions, 'TenantPosts'];

type SitePostsActions = CRUD | 'publish' | 'unpublish';

type SitePostsAbilities = [SitePostsActions, 'SitePosts'];

type SiteApplicationsActions =
  | CRUD
  | 'setAsDefault'
  | 'removeFromDefault'
  | 'grantPermission'
  | 'revokePermission';

type SiteApplicationsAbilities = [SiteApplicationsActions, 'SiteApplications'];

type SitePermissionsActions = CRUD | 'grant' | 'revoke';

type SitePermissionsAbilities = [SitePermissionsActions, 'SitePermissions'];

type SiteFaqsActions = CRUD | 'publish' | 'unpublish';

type SiteFaqsAbilities = [SiteFaqsActions, 'SiteFaqs'];
type MessagesActions = CRUD | 'publish' | 'duplicate';

type SiteMessagesAbilities = [MessagesActions, 'SiteMessages'];

type TenantMessagesAbilities = [MessagesActions, 'TenantMessages'];

type SiteIssuesActions = 'read' | 'downloadXls' | 'editStatus';

type SiteIssuesAbilities = [SiteIssuesActions, 'SiteIssues'];

type IssueManagersActions = CRUD | 'resendInvitation';

type IssueManagersAbilities = [IssueManagersActions, 'IssueManagers'];

type IssuesManagementActions = 'read' | 'downloadXls' | 'editStatus';

type IssuesManagementAbilities = [IssuesManagementActions, 'IssuesManagement'];

export type AppAbility = MongoAbility<
  | Abilities
  | TenantAdministratorsAbilities
  | TenantEmployeesAbilities
  | TenantEmployeesJoinRequestAbilities
  | TenantEmployeesAccessAbilities
  | TenantApplicationsAbilities
  | TenantEmployeesDisplayedApplicationsAbilities
  | SiteTenantsAbilities
  | SiteAdministratorsAbilities
  | SiteEventsAbilities
  | TenantPostsAbilities
  | SitePostsAbilities
  | SiteApplicationsAbilities
  | SitePermissionsAbilities
  | SiteFaqsAbilities
  | SiteMessagesAbilities
  | TenantMessagesAbilities
  | SiteIssuesAbilities
  | IssueManagersAbilities
  | IssuesManagementAbilities
>;

export type Role = 'owner' | 'admin' | 'readonly' | 'master';

export function definePermissions(
  viewContext: ViewContext,
  role: Role,
  userToken: string,
  hasAccessToIssuesManagement: boolean,
) {
  const builder = new AbilityBuilder<AppAbility>(createMongoAbility);

  switch (viewContext) {
    case 'tenant':
      tenantContextPermissions(builder, role, userToken);
      break;
    case 'site':
      siteContextPermissions(
        builder,
        role,
        userToken,
        hasAccessToIssuesManagement,
      );
      break;
    case 'issue-manager':
      issueManagerPermissions(builder);
      break;
    default:
      break;
  }

  return builder.build();
}
