import meiliSearchApiClient from 'src/api/clients/meilisearch';
import siteApi from 'src/api/clients/site';
import { SearchState } from 'src/features/tenant/types/interfaces/search-state.refactor';
import { MeiliSearchResponse } from 'src/types/responses/meilisearch';
import { calculatePaginationOffset } from 'src/utils/calculate-pagination-offset';
import { Tenant } from '../types/tenant';

export const fetchTenants = async (
  siteToken: string,
  searchState: SearchState,
  page: number,
  perPage: number,
) => {
  const { data } = await meiliSearchApiClient.post<MeiliSearchResponse<Tenant>>(
    `/indexes/Tenant-${siteToken}/search`,
    {
      ...searchState,
      limit: perPage,
      offset: calculatePaginationOffset(page, perPage),
    },
  );

  return data;
};

export const inviteTenant = async (
  tenantOwnerEmail: string,
  tenantOwnerPhoneNumber: string,
  tenantName: string,
) => {
  await siteApi.post('/tenant-invitations', {
    tenantOwnerEmail,
    tenantOwnerPhoneNumber,
    tenantName,
  });
};

export const editTenant = async (tenantToken: string, name: string) => {
  //TODO: Implement this when the endpoint is ready
  console.log(name, tenantToken);
};

export const removeTenant = async (tenantToken: string) => {
  //TODO: Implement this when the endpoint is ready
  console.log(tenantToken);
};

export const resendInvitation = async (tenantToken: string) => {
  //TODO: Implement this when the endpoint is ready
  console.log(tenantToken);
};

export const removeInvitation = async (invitationToken: string) => {
  await siteApi.delete(`/tenant-invitations/${invitationToken}`);
};
