import {
  Box,
  CircularProgress,
  Fade,
  MenuItem,
  Pagination as PaginationMui,
  Select,
  Theme,
  Typography,
  styled,
  useMediaQuery,
} from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import GoToPageInput from '../atoms/pagination/go-to-page-input';

interface Props {
  disabled: boolean;
  perPage: number;
  totalPages: number;
  rowsCount: number;
  page: number;
  changePage: (newPage: number) => void;
  handlePerPageChange: (newLimit: number) => void;
  perPageId?: string;
  perPageOptions?: number[];
  isFetching: boolean;
}

const PaginationRoot = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  padding: theme.spacing(2),
}));

export const perPageDefaultOptions = [10, 20, 50, 100];

export const Pagination = ({
  changePage,
  perPage,
  totalPages,
  page,
  handlePerPageChange,
  perPageId,
  perPageOptions = perPageDefaultOptions,
  isFetching,
  ...other
}: Props) => {
  const { t } = useTranslation();

  const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  // Ensure that the page is not greater than the total number of pages, for example when the user deleted all items from the last page
  useEffect(() => {
    if (totalPages === 0) {
      changePage(1);

      return;
    }

    if (page > totalPages) {
      changePage(totalPages);
    }
  }, [totalPages]);

  return (
    <PaginationRoot
      {...other}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
      <Box sx={{ flex: 1 }}>
        {totalPages > 0 ? (
          <GoToPageInput
            changePage={changePage}
            page={page}
            totalPages={totalPages}
          />
        ) : null}
      </Box>
      <Box sx={{ flex: 3, display: 'flex', justifyContent: 'center' }}>
        <PaginationMui
          count={totalPages}
          shape="rounded"
          page={page}
          boundaryCount={lgDown ? 1 : 2}
          siblingCount={lgDown ? 0 : 1}
          size={mdDown ? 'small' : 'medium'}
          onChange={(_, newPage) => changePage(newPage ?? page)}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: 2,
          flex: 1,
        }}>
        <Fade in={isFetching} unmountOnExit>
          <CircularProgress size={20} />
        </Fade>
        <Typography
          color="textSecondary"
          sx={{
            alignItems: 'center',
            display: 'flex',
          }}
          variant="body2"
          whiteSpace="nowrap">
          {t('common:perPage')}
        </Typography>
        <Select
          size="small"
          value={perPage}
          id={perPageId}
          sx={{ fontSize: 14 }}
          onChange={e => handlePerPageChange(+e.target.value)}>
          {perPageOptions.map(perPageValue => (
            <MenuItem value={perPageValue} key={perPageValue}>
              {perPageValue}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </PaginationRoot>
  );
};
