export const lightPrimary = {
  contrast: '#fff',
  dark: '#2B2B2B',
  light: '#F1F8FB',
  main: '#000',
  borders: '#BFDFEA',
  hoverOutlined: '#6EBAD3',
};

export const lightSecondary = {
  contrast: '#ffffff',
  dark: '#3e4b54',
  light: '#7992a2',
  main: '#506176',
};

export const lightError = {
  contrast: '#ffffff',
  dark: '#A53531',
  light: '#EF6F6B',
  main: '#F97066',
};

export const lightWarning = {
  contrast: '#ffffff',
  dark: '#975B1E',
  light: '#E09B55',
  main: '#FDB022',
  secondary: '#D69D61',
};

export const lightInfo = {
  contrast: '#ffffff',
  dark: '#0B4E8D',
  light: '#3F8CD4',
  main: '#4190DB',
};

export const lightSuccess = {
  contrast: '#ffffff',
  dark: '#3B873E',
  light: '#7BC67E',
  main: '#27AB6E',
};

export const lightBackground = {
  default: '#ffffff',
  paper: '#ffffff',
};

export const lightText = {
  primary: '#212831',
  secondary: '#A0A19B',
};

export const lightNeutral = {
  100: '#F1F8FB',
  200: '#DEDBD8',
  300: '#DEDBD8',
  400: '#B7BDCC',
  500: '#F0EDEA',
  600: '#7C85A2',
  700: '#3F455A',
  800: '#2A2F3C',
  900: '#1E212A',
};

export const lightActions = {
  focus: '#E5F2F7',
  active: '#212831',
  hover: '#F1F8FB',
  selected: 'rgba(33, 40, 49, 0.08)',
  disabled: '#AEAEAE',
  disabledBackground: '#F0EDEA',
};

export const darkPrimary = {
  contrast: '#111318',
  dark: '#ffffff',
  light: '#182027',
  main: '#EDEDED',
  borders: '#548697',
  hoverOutlined: '#6EBAD3',
};

export const darkSecondary = {
  contrast: '#ffffff',
  dark: '#3e4b54',
  light: '#7992a2',
  main: '#506176',
};

export const darkError = {
  contrast: '#ffffff',
  dark: '#A53531',
  light: '#EF6F6B',
  main: '#F97066',
};

export const darkWarning = {
  contrast: '#ffffff',
  dark: '#975B1E',
  light: '#E09B55',
  main: '#FDB022',
  secondary: '#D69D61',
};

export const darkInfo = {
  contrast: '#ffffff',
  dark: '#0B4E8D',
  light: '#3F8CD4',
  main: '#4190DB',
};

export const darkSuccess = {
  contrast: '#ffffff',
  dark: '#3B873E',
  light: '#7BC67E',
  main: '#27AB6E',
};

export const darkBackground = {
  default: '#111318',
  paper: '#111318',
};

export const darkText = {
  primary: '#EDEDED',
  secondary: '#A0A19B',
};

export const darkNeutral = {
  100: '#182027',
  200: '#434342',
  300: '#626261',
  400: '#B7BDCC',
  500: '#323232',
  600: '#7C85A2',
  700: '#3F455A',
  800: '#2A2F3C',
  900: '#1E212A',
};

export const darkActions = {
  focus: '#2c414b',
  active: '#EDEDED',
  hover: '#182027',
  selected: 'rgba(33, 40, 49, 0.08)',
  disabled: '#535353',
  disabledBackground: '#242424',
};

export const approvedStatus = {
  approved: '#27AB6E',
  notApproved: '#538ACE',
};

export const lightRemovalNotification = {
  main: 'rgba(240, 68, 56, 0.02)',
};

export const darkRemovalNotification = {
  main: 'rgba(240, 68, 56, 0.07)',
};

export const lightErrorIndicator = {
  main: 'rgba(240, 68, 56, 0.02)',
  dark: '#F04438',
};

export const darkErrorIndicator = {
  main: 'rgba(240, 68, 56, 0.07)',
  dark: '#BB4A42',
};

export const darkPickersSelectedBackground = {
  main: '#19252C',
};

export const lightPickersToday = {
  border: '#DDEFF5',
};

export const darkPickersToday = {
  border: '#324453',
};

export const rememberHighlight = {
  main: '#B54708',
  dark: '#BB4A42',
  background: 'rgba(240, 68, 56, 0.02)',
  darkBackground: 'rgba(240, 68, 56, 0.07)',
  border: 'rgba(240, 68, 56, 0.4)',
  darkBorder: 'rgba(187, 74, 66, 0.7)',
};

export const assetsBackground = {
  light: '#EAE9E9',
  dark: '#474545',
};

export const status = {
  success: '#53A973',
  pending: '#D69D61',
  error: '#F04438',
  info: '#1070CA',
};

export const tableRowWithErrorBackground = {
  light: '#F0443805',
  dark: '#F0443812',
};
