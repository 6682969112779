import { Card, List, MenuItem, Popper } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { MutableRefObject } from 'react';
import { useNavigate } from 'react-router-dom';
import { siteMeilisearchSearchKeyKeys } from 'src/api/query-keys/site/meilisearch-search-key';
import { useAvailableSites } from 'src/hooks/api/use-available-sites';
import { useUserStore } from 'src/store/user-store';
import { UserRole } from 'src/types/enum/user-role';

interface Props {
  anchorRef: MutableRefObject<HTMLElement | null>;
  open: boolean;
  onClose: () => void;
  onMouseLeave?: () => void;
  onMouseEnter?: () => void;
  placement: 'bottom-end' | 'right-start';
}

export const AvailableSitesPopper = ({
  anchorRef,
  open,
  onClose,
  onMouseLeave,
  onMouseEnter,
  placement,
}: Props) => {
  const {
    setViewContext,
    setIsChangingDirectory,
    setDirectoryToken,
    setAdminToken,
    setRole,
  } = useUserStore();

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const { data: availableSites } = useAvailableSites();

  const handleChangeContext = (
    directoryToken: string,
    adminToken: string,
    role: UserRole,
  ) => {
    setViewContext('site');
    setIsChangingDirectory(true);
    setDirectoryToken(directoryToken);
    setAdminToken(adminToken);
    setRole(role);

    onClose();

    queryClient.invalidateQueries({
      queryKey: siteMeilisearchSearchKeyKeys.details(),
    });

    navigate('/');
  };

  return (
    <Popper
      anchorEl={anchorRef.current}
      open={open}
      placement={placement}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      sx={{
        margin: placement === 'bottom-end' ? '10px 0 !important' : undefined,
        top: placement === 'right-start' ? '-10px !important' : undefined,
      }}>
      <Card variant="outlined" sx={{ maxHeight: '90vh', overflowY: 'auto' }}>
        <List>
          {availableSites?.resources?.map(site => (
            <MenuItem
              key={site.token}
              onClick={() => {
                handleChangeContext(site.token, site.siteAdminToken, site.role);
              }}>
              {site.name}
            </MenuItem>
          ))}
        </List>
      </Card>
    </Popper>
  );
};
