import { CssBaseline, ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ReactNode } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';
import { useSettingsStore } from 'src/store/settings-store';
import { createCustomTheme } from 'src/theme';
import { LocalizationProvider } from './localization-provider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

export const Providers = ({ children }: { children: ReactNode }) => {
  const settingsDirection = useSettingsStore(state => state.direction);
  const settingsTheme = useSettingsStore(state => state.theme);

  const theme = createCustomTheme({
    direction: settingsDirection,
    theme: settingsTheme,
  });

  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
          <LocalizationProvider>{children}</LocalizationProvider>
          <Toaster
            position="top-center"
            toastOptions={{
              style: {
                wordBreak: 'break-word',
              },
            }}
          />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
};
