import axios from 'axios';
import { attachAcceptLanguage } from 'src/api/interceptors/attach-accept-language';
import { attachAccessToken } from 'src/api/interceptors/attach-access-token';
import { attachDirectoryToken } from '../interceptors/attach-directory-token';

const siteApiV2 = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}/site-admin/v2/sites`,
});

siteApiV2.interceptors.request.use(attachAcceptLanguage);
siteApiV2.interceptors.request.use(attachAccessToken);
siteApiV2.interceptors.request.use(attachDirectoryToken);

export default siteApiV2;
